html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: white;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#root {
  height: 100%; }
  #root > div {
    position: relative;
    min-height: 100%; }

.body {
  height: 100%; }
  .body .body-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column; }
    .body .body-wrapper .body-stretch {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch; }

.body-wrapper {
  height: 100%; }

h1, h2, h3, h4, h5 {
  user-select: none;
  font-family: "DM Serif Display", serif;
  font-weight: 700; }

h1 {
  font-size: 4em;
  font-weight: 700; }

h2 {
  font-size: 3em;
  font-weight: 700; }

h3 {
  font-size: 2em;
  font-weight: 700; }

.bg-gradient {
  background: #005fb9;
  background: linear-gradient(164deg, #005fb9 23%, #72cedf 100%);
  color: white; }

.bg-light {
  background: #FDFAD4; }

a {
  color: #131614;
  text-decoration: none !important;
  transition: color 200ms ease-in-out;
  cursor: pointer; }
  a:hover {
    color: #131614; }
    a:hover .video-screenshot {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.35); }
  a.no-color {
    color: inherit; }
  a.grey-to-black {
    color: #c3cbc6; }
    a.grey-to-black:hover {
      color: #131614; }

.clickable {
  cursor: pointer; }

.oscilate-vertical {
  position: relative;
  animation: oscilate-vertical 1.5s infinite; }

@keyframes oscilate-vertical {
  0% {
    top: -2px; }
  50% {
    top: 2px; }
  100% {
    top: -2px; } }

.brand-font {
  font-family: "DM Serif Display", serif;
  font-weight: 900; }

.jumbotron {
  background: transparent;
  margin-bottom: 0px;
  position: relative;
  border-radius: 20px;
  background: transparent; }
  .jumbotron .jumbotron-popout {
    position: absolute;
    top: -100px;
    background: white;
    border-radius: 2px;
    padding: 40px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    color: #2b312d; }
  .jumbotron .jumbotron-header-verbs {
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 5px; }
  .jumbotron.jumbotron-mega-pad {
    padding-top: 125px;
    padding-bottom: 125px; }
  .jumbotron h1 {
    font-size: 6em;
    font-weight: 400;
    margin-bottom: 10px; }
  .jumbotron p {
    margin-bottom: 25px; }
  .jumbotron p.lead {
    font-size: 2em; }
  .jumbotron h3 {
    margin-bottom: 10px; }
  .jumbotron.jumbotron-white {
    background: #fafafa; }
  .jumbotron.jumbotron-clear {
    background: transparent; }
  .jumbotron.jumbotron-primary {
    background: #D00000;
    color: white; }
  .jumbotron.jumbotron-secondary {
    background: #FFBA08;
    color: white; }
  .jumbotron.jumbotron-light {
    background: #fafafa; }

.text-heavy {
  font-weight: 700; }

.text-primary {
  color: #D00000; }

.text-secondary {
  color: #FFBA08; }

.text-lg {
  font-size: 130%; }

.text-sm {
  font-size: 70%; }

.text-300 {
  font-weight: 300; }

.text-brand-pattycake {
  font-family: 'Grand Hotel';
  font-weight: 400; }

.translucent-hover {
  transition: opacity 200ms ease-in-out;
  opacity: 1; }
  .translucent-hover:hover {
    opacity: .5; }

.translucent {
  opacity: .5; }

.semi-translucent {
  opacity: .85; }

.vertical-divider {
  font-size: 12px;
  opacity: .5;
  position: relative;
  top: -2px;
  padding-left: 5px;
  padding-right: 5px; }

.screen-height-container {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100vh; }
  .screen-height-container.screen-height-container-with-peek {
    min-height: calc(100vh - 20px) !important; }
  .screen-height-container .shc-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2; }
    .screen-height-container .shc-background .shc-carousel {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      background-attachment: fixed;
      opacity: 0;
      transition: opacity 2000ms ease-in-out; }
      .screen-height-container .shc-background .shc-carousel.shc-carousel-current {
        opacity: 1; }
    .screen-height-container .shc-background .shc-background-gradient {
      position: absolute !important;
      top: 0px !important;
      height: 30% !important;
      width: 100% !important;
      min-height: 90px !important;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)) !important; }
  .screen-height-container .shc-content {
    position: relative;
    z-index: 10;
    height: 100vh;
    display: block; }
    .screen-height-container .shc-content.shc-content-center {
      display: flex;
      justify-content: space-around;
      align-items: center; }
  .screen-height-container .shc-top-left-button {
    position: absolute;
    top: 20px;
    left: 20px; }
  .screen-height-container .shc-top-right-button {
    position: absolute;
    top: 20px;
    right: 20px; }
  .screen-height-container .shc-content-split {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    /* Smartphones (portrait) ----------- */ }
    @media only screen and (max-width: 720px) {
      .screen-height-container .shc-content-split {
        display: block; } }
    .screen-height-container .shc-content-split .shc-content-half {
      flex: .5;
      position: relative; }
  .screen-height-container .shc-bottom-content {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 11; }
  .screen-height-container .shc-top-center-progress {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    pointer-events: none;
    display: flex;
    justify-content: space-around;
    padding-top: 28px; }
    .screen-height-container .shc-top-center-progress .fa-circle {
      color: #D00000;
      margin-left: 10px;
      margin-right: 10px;
      font-size: 14px; }

p {
  line-height: 1.3; }
  p.push-down {
    margin-bottom: 30px; }

.shc-padded {
  padding-top: 50px;
  padding-bottom: 50px; }

.shc-padded-top {
  padding-top: 50px; }

.shc-transparent {
  background: none; }

.shc-primary {
  color: white;
  background: #D00000; }

.shc-secondary {
  color: white;
  background: #FFBA08; }

.shc-third {
  color: white;
  background: #3F88C5; }

.shc-success {
  color: white;
  background: #4FBA62; }

.shc-gs1 {
  color: white;
  background: #2b312d; }

.shc-grey {
  color: #2b312d;
  background: #c3cbc6; }

.shc-gs10 {
  color: #2b312d;
  background: white; }

.page-section {
  padding-top: 50px;
  padding-bottom: 50px; }

.bg-primary-light {
  background: #ff9d9d; }

.bg-white-home-text {
  background: white !important;
  padding: 5px;
  border-radius: 2px; }

.bg-primary {
  background: #D00000 !important; }

.bg-secondary {
  background: #FFBA08 !important; }

.flex-split {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center; }

.flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; }

.list-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .list-left .list-item-grow {
    flex-grow: 1; }
  .list-left .list-item {
    margin-right: 20px; }
    .list-left .list-item:last-child {
      margin-right: 0px; }

.list-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }
  .list-right .list-item {
    margin-left: 20px; }
    .list-right .list-item:first-child {
      margin-left: 0px; }

.rounded-image {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12); }

hr.mega {
  margin-top: 75px;
  margin-bottom: 75px; }

.underlined {
  position: relative; }
  .underlined::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 50%;
    width: 0px;
    height: 4px;
    border-radius: 4px;
    background: #D00000;
    transition: width 250ms ease-in-out, left 250ms ease-in-out; }
  .underlined:hover::after {
    width: 100%;
    left: 0%; }
  .underlined.underlined-primary::after {
    background: #D00000; }
  .underlined.underlined-secondary::after {
    background: #FFBA08; }
  .underlined.underlined-light::after {
    background: rgba(255, 255, 255, 0.25); }

.headshot-wrapper {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 100%; }

.headshot {
  position: absolute;
  border-radius: 1000px;
  width: 100%; }

.back-filled {
  position: relative;
  z-index: 1; }
  .back-filled::after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0px;
    top: 0px;
    left: 0px;
    width: 0px;
    background: #D00000;
    border-radius: 1px;
    transition: width 300ms ease-in-out, left 300ms ease-in-out; }
  .back-filled:hover::after {
    width: 100%; }
  .back-filled.back-filled-primary::after {
    background: #D00000; }
  .back-filled.back-filled-secondary::after {
    background: #FFBA08; }
  .back-filled.back-filled-light::after {
    background: rgba(255, 255, 255, 0.25); }

.col-extra-padded {
  padding: 40px; }

.clickable-field-description {
  cursor: pointer; }
  .clickable-field-description:hover {
    color: #FFBA08; }

.small-description {
  color: #5a685f;
  font-size: 14px;
  font-weight: 400;
  text-align: center; }

.col-icon {
  margin-bottom: 25px; }

.series-of-dots {
  font-size: 10px; }

.no-margin-bottom {
  margin-bottom: 0; }

.no-margin-top {
  margin-top: 0; }

.neg-margin-top-100 {
  margin-top: -100px; }

.row-padded {
  margin-top: 30px;
  margin-bottom: 30px; }

.row-push-down {
  margin-bottom: 65px; }

.spacer {
  margin-bottom: 200px; }

.list-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .list-center .list-item {
    margin-left: 20px;
    margin-right: 20px; }

.white-container {
  background: white;
  color: #424d46;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08) !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 32px; }

.sketch {
  width: 100%;
  height: 100%; }
  .sketch canvas {
    width: 100%;
    height: 100%;
    outline: none; }
    .sketch canvas:focus {
      outline: none; }

.sketch-list {
  padding-left: 50px;
  padding-right: 50px; }
  .sketch-list .sketch-thumbnail .sketch-thumbnail-number {
    vertical-align: bottom;
    padding-bottom: 5px;
    font-size: 12px;
    color: #8c9c91; }
  .sketch-list .sketch-thumbnail .sketch-thumbnail-link {
    color: #000;
    font-weight: 800;
    font-size: 20px;
    margin-right: 10px; }
  .sketch-list .sketch-thumbnail .sketch-thumbnail-description {
    font-weight: 300; }

#list-randomizer textarea {
  height: 400px !important;
  max-height: 400px;
  overflow: scroll; }

.video-screenshot {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
  transition: box-shadow 200ms ease-in-out; }

.tool-link {
  padding: 20px;
  display: block;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0);
  margin-bottom: 10px;
  transition: box-shadow 200ms ease-in-out; }
  .tool-link:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); }
  .tool-link p:last-child {
    margin-bottom: 0px; }

canvas.tool-output-canvas {
  transition: opacity 200ms ease-in-out; }

svg.tool-output-svg {
  width: 100%;
  height: 450px; }

.align-col-with-input {
  padding-top: 32px; }
