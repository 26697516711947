.header-bumper {
  height: 75px;
  display: block; }

.header {
  background: transparent;
  height: 75px;
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
  transition: height 400ms ease-in-out, padding-left 400ms ease-in-out, padding-right 400ms ease-in-out; }
  .header .menu-btn {
    cursor: pointer; }
    .header .menu-btn:hover {
      color: #3F88C5; }
  .header .brand-font {
    font-size: 24px;
    font-family: "DM Serif Display", serif; }
  .header .hidden-top {
    opacity: 1;
    transition: opacity 400ms ease-in-out; }
  .header .flex-split {
    height: 100%; }
  .header.header-top {
    height: 75px;
    padding-left: 20px;
    padding-right: 20px; }
    .header.header-top .hidden-top {
      opacity: 0;
      pointer-events: none; }
