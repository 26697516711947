#menu {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  max-width: 100vw;
  padding-top: 50px;
  padding-right: 50px;
  transition: opacity ease-in-out 400ms;
  z-index: 100;
  opacity: 1;
  overflow-y: auto;
  pointer-events: none;
  background: #2b312d; }
  #menu.menu-open {
    opacity: 1;
    pointer-events: auto; }
  #menu.menu-closed {
    pointer-events: none;
    opacity: 0;
    font-size: 16px; }
  #menu .menu-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start; }
  #menu .menu-item {
    font-weight: 300;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: font-size 400ms ease-in-out;
    margin: 5px;
    margin-right: -5px;
    display: block; }
    #menu .menu-item.menu-item-emphasized {
      font-weight: 700; }
  #menu .menu-footer {
    margin-top: 100px;
    margin-bottom: 40px; }
  #menu .menu-exit-btn {
    cursor: pointer;
    position: absolute;
    top: 13px;
    right: 25px;
    opacity: .1;
    color: white; }
    #menu .menu-exit-btn:hover {
      color: #131614;
      opacity: 1; }
  #menu a {
    background: none; }
    #menu a.menu-link {
      color: white;
      font-family: "DM Serif Display", serif;
      font-weight: 400;
      text-decoration: none;
      font-size: 50px; }
      #menu a.menu-link:hover {
        color: #131614; }
