.footer-wrapper {
  margin-top: 50px;
  padding-bottom: 30px;
  padding-left: 25px;
  padding-right: 10px;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  pointer-events: none;
  font-weight: 300;
  color: #8c9c91; }
  .footer-wrapper p {
    margin-top: 5px;
    margin-bottom: 0px; }
  .footer-wrapper a {
    pointer-events: auto; }
  .footer-wrapper .footer-links {
    pointer-events: auto;
    font-size: 14px;
    color: #a8b3ac; }
    .footer-wrapper .footer-links a {
      margin-left: 5px;
      margin-right: 5px; }
  .footer-wrapper .footer-group {
    display: flex;
    flex-direction: row; }
    .footer-wrapper .footer-group .footer-item {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px; }
